@font-face {
    font-family: 'Panam';
    src:  url('./../font/NewPanamSkyline-Regular.woff') format('truetype');
  }


*, html, body{
    margin: 0px;
    padding: 0px;
    font-family: 'Panam';
}

body{
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
canvas{
    width: 100vw;
    height: 100vh;
}
#container{
    width: 100vw;
    height: 100vh;
}

#qr__code_cntainer{
    position: fixed;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

#qr__code_cntainer img{
    display: block;
    position: relative;
    float: left;
    width: 10vw;
    height: 10vw;
    align-self: center
}

#encor__link{
    position: fixed;
    z-index: 5;
    right: 10px;
    bottom: 10px;
}

#encor__link a {
    color: black!important;
    text-decoration: none;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    #qr__code_cntainer img{
        display: block;
        position: relative;
        float: left;
        width: 20vw;
        height: 20vw;
        align-self: center
    }
  }
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 600px) {
    #qr__code_cntainer img{
        display: block;
        position: relative;
        float: left;
        width: 30vw;
        height: 30vw;
        align-self: center;
    }

    #encor__link a {
        color: black!important;
        text-decoration: none;
        font-size: 10px;
    }
}